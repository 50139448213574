/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { QueryProvider } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';

import { Pep } from './Pep';

export const PepDesktopContainer = (props) => {
  const { isCustomerIdentified = false } = useThdCustomer() || {};

  const store = useStore({ varnish: false });
  const { storeId, storeZip, isLocalized } = store || {};
  const defaultVariables = {
    storeId,
    zipCode: storeZip,
    skipInstallServices: false,
    skipSpecificationGroup: false,
    isBrandPricingPolicyCompliant: isLocalized ? isCustomerIdentified : false
  };

  const merge = {
    productClientOnlyProduct: {
      queries: ['product', 'clientOnlyProduct'],
      fn: ({ query, mounted }) => {
        if (!mounted) return query;
        if (query === 'product' || query === 'clientOnlyProduct') {
          return 'productClientOnlyProduct';
        }
        return query;
      }
    }
  };

  return (
    <QueryProvider
      {...props}
      cacheKey="pep-page"
      merge={merge}
      defaultVariables={defaultVariables}
    >
      <Pep />
    </QueryProvider>
  );
};

PepDesktopContainer.displayName = 'PepDesktopContainer';
